<!-- 看竞争 -->
<template>
	<div class="app-container">
		<formItem
			class="queryForm"
			style="margin: 10px 0"
			:formItems="formItems"
			@cancel="$router.go(-1)"
			:defaultData="formModel"
			:showFooter="false"
		>
		</formItem>
		<div class="views_wrap">
			<empty v-if="!tableRealData.length" />
			<div
				v-for="(item, inx) in tableRealData"
				:key="item.lineId"
				:class="`view_item ${isFullScreen && fullScreenInx === inx && 'fullscreen'}`"
			>
				<div @click="() => handleTitle(inx)">
					<el-row class="titleRow">
						<el-col :span="2"><i :class="activeNames.includes(inx) ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i></el-col>
						<el-col :span="8" v-for="ite in titleList" :key="ite.key">
							<div class="titleText">
								<span>{{ ite.label }}: </span>
								<span>{{ ite.formatter ? ite.formatter(item[ite.key]) : item[ite.key] || '' }}</span>
							</div>
						</el-col>
						<el-col :span="14" style="text-align: right; padding-right: 10px" v-show="!isDownLoading">
							<!-- <el-button size="mini" type="primary" icon="el-icon-edit" @click.stop="() => handleEditAnalysis(item)"> 编辑 </el-button> -->
							<el-button
								size="mini"
								type="danger"
								icon="el-icon-delete"
								@click.stop="() => handleDelAnalysis(item, { rowNum: null })"
								v-if="permissionControlBtns(pageName, 'Delete')"
							>
								删除
							</el-button>
						</el-col>
					</el-row>
				</div>
				<el-collapse-transition>
					<div class="wo_table_wrap" v-show="activeNames.includes(inx)">
						<div class="wo_table_item" :style="isFullScreen && `height: 100vh`">
							<baseTable
								:columns="columns"
								:showHeader="false"
								:showPagination="false"
								:dataList="item.tableData"
								style="margin: 20px 60px 60px"
								:showBorder="false"
							/>
						</div>
					</div>
				</el-collapse-transition>
			</div>
		</div>
		<!-- 添加企业内部分析 -->
		<el-dialog :title="dialogTitle" top="30vh" center :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false">
			<formItem
				class="formItem"
				:formItems="dialogItems"
				@submit="this.addAnalysisSubmit"
				@cancel="dialogVisible = false"
				:defaultData="dialogModel"
				style="margin: 0 50px"
			>
			</formItem>
		</el-dialog>
		<!-- 新增编辑行 以及单元格 -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="600px"
			center
			:visible.sync="addNodeVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="dialogNodeItems"
				@submit="this.addAnalysisSubmit"
				@cancel="addNodeVisible = false"
				:defaultData="dialogFormModel"
			>
			</formItem>
		</el-dialog>
		<!-- 导入按钮的弹窗 -->
		<!-- <Upload :visible.sync="dialogImportVisible" :config="uploadConfig" /> -->
	</div>
</template>

<script>
import formItem from '@/views/components/components-form-item/index.vue';
import baseTable from '@/views/components/components-base-table';
import empty from '@/views/components/components-empty/index.vue';
// import Upload from '@/components/Upload/index.vue';
// import { debounce } from 'loadsh';
import { Loading } from 'element-ui';
// import { printDiv } from '@/utils/util';
export default {
	name: 'marketInsightindustry',
	components: { formItem, baseTable, empty },
	props: {
		pageName: {}
	},
	data() {
		return {
			radarLoading: false,
			visible: true,
			dialogVisible: false,
			dialogModel: {},
			dialogItems: [
				{
					label: '产品线',
					key: 'productId',
					type: 'select',
					class: 'pruductClass',
					defaultFirstOption: true,
					options: () => {
						const { setData } = this.$store.state;

						return setData.options.productId || [];
					},
					optEdit: true,
					footerRender: true,
					optProps: { add: '/v1/common/productConfig/save', del: '/v1/common/productConfig/delete', key: 'name' },
					rules: [{ required: true, message: '请选择', trigger: 'change' }],
					span: 24
				}
			],
			formItems: [
				{
					label: '时间',
					key: 'annual',
					type: 'year',
					span: 6,
					labelWidth: '60px'
				},
				{
					label: '产品线',
					key: 'productId',
					type: 'select',
					async: () =>
						this.$axios
							.get('/v1/common/productConfig/getList', { request: {} })
							.then((res) => res.map(({ id, name }) => ({ dictId: id, dictName: name }))),
					span: 6
				},
				{
					span: 3,
					labelWidth: '0px',
					style: 'min-width: 100px',
					render: () => (
						<el-button size="small" type="primary" style="margin-top: 5px" icon="el-icon-search" onclick={this.queryData}>
							查询
						</el-button>
					)
				},
				this.permissionControlBtns(this.pageName, 'Add') && {
					span: 9,
					labelWidth: '0px',
					style: 'text-align: right;min-width: 310px',
					render: () => (
						<div style="min-width: 310px">
							<el-button
								size="small"
								type="primary"
								style="margin-top: 5px"
								icon="el-icon-plus"
								onclick={() => {
									this.dialogModel = {};
									this.dialogTitle = '添加企业内部分析';
									this.dialogType = 'add';
									this.dialogVisible = true;
								}}
							>
								添加企业内部分析
							</el-button>
						</div>
					)
				}
			].filter((v) => v),
			formModel: { annual: new Date().getFullYear().toString() },
			columns: [
				{
					key: 'opportunity',
					showOverflow: false,
					align: 'left',
					render: this.getRenderByKey('opportunity')
				},
				{
					key: 'threaten',
					showOverflow: false,
					align: 'left',
					render: this.getRenderByKey('threaten')
				}
			],
			dataList: [],
			titleList: [
				// {
				// 	label: '时间',
				// 	key: 'createTime',
				// 	formatter: (val) => val && moment(val).format('YYYY-MM-DD')
				// },
				{ label: '产品线', key: 'productName' }
				// { label: '所属行业', key: 'industry' }
			],
			addNodeVisible: false,
			addTagVisible: false,
			dialogFormModel: {},
			dialogTitle: '新增',
			dialogType: '',
			// debounceResize: debounce(this.resize, 300),
			activeNames: [0],
			isDownLoading: false,
			dialogImportVisible: false,
			// 导入配置
			// uploadConfig: {
			// 	dictId: 'importTargetInfo',
			// 	groupId: 'importTemplate',
			// 	importUrl: '/v1/performance/target/importTargetInfo',
			// 	successMsg: '年度目标差距分析导入成功',
			// 	callBack: () => {
			// 		this.queryData();
			// 		this.dialogImportVisible = false;
			// 	}
			// },
			isFullScreen: false,
			fullScreenInx: '',
			tableRealData: [],
			editNodeData: {},
			showOperation: [],
			textareaVal: ''
		};
	},
	watch: {},
	computed: {
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		dialogNodeItems() {
			const { realKey } = this.dialogFormModel || {};

			const arr = [
				{
					label: '',
					key: realKey || 'fieldData',
					type: 'textarea',
					span: 24,
					// rows: 4,
					// autosize: false,
					rules: { required: true, message: '请输入', trigger: 'blur' }
				}
			];

			return arr;
		}
	},
	async mounted() {
		// 查询数据
		this.queryData();
	},

	methods: {
		// 查询数据视图
		queryData() {
			const loading = Loading.service({ target: '.views_wrap' });

			this.$axios
				.post('/v1/doRightThing/lookMyself/getList', { request: { ...this.formModel } })
				.then((res) => {
					loading.close();
					if (res.code) {
						return;
					}
					const arr = [];

					this.$delete(res, '_responseStatusCode');

					res.list.forEach((item) => {
						const { so, st, wo, wt } = item;

						arr.push({
							...item,
							tableData: [
								{ ...item, opportunity: so || ' S + O \n 优势 + 机会', threaten: st || ' S + T \n 优势 + 威胁' },
								{ ...item, opportunity: wo || ' W + O \n 劣势 + 机会', threaten: wt || ' W + T \n 劣势 + 威胁' }
							]
						});
					});
					this.tableRealData = arr;
				})
				.catch(() => {
					loading.close();
				});
		},
		// 添加/编辑[分析]提交
		addAnalysisSubmit(form) {
			console.log(this.editNodeData, this.dialogFormModel, 'edit');
			form.validate((valid) => {
				if (valid) {
					let params = {
						...this.dialogModel
					};

					if (this.dialogType === 'editNode') {
						params = { ...params, ...this.dialogFormModel };
					}
					const loading = Loading.service({ target: '.formItem' });

					this.$axios
						.post('/v1/doRightThing/lookMyself/save', {
							request: params
						})
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.addNodeVisible = false;
							this.$message.success('保存成功');
							this.dialogModel = {};
							this.dialogFormModel = {};
							this.queryData();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		},

		// 编辑节点
		handleEdit(e, row, inx, key) {
			this.dialogType = 'editNode';
			this.addNodeVisible = true;
			this.dialogTitle = '编辑';
			const data = { ...row };

			switch (key) {
				case 'opportunity':
					// eslint-disable-next-line no-nested-ternary
					data.realKey = inx === 0 ? 'so' : inx === 1 ? 'wo' : '';
					break;
				case 'threaten':
					// eslint-disable-next-line no-nested-ternary
					data.realKey = inx === 0 ? 'st' : inx === 1 ? 'wt' : '';
					break;

				default:
					break;
			}
			this.dialogFormModel = data;
		},
		// 编辑行业分析
		handleEditAnalysis({ id, productId, productName, industry, tableId }) {
			this.dialogType = 'editAnalysis';
			const params = {
				id,
				tableId,
				productId,
				productName,
				industry
			};

			this.dialogModel = params;
			this.dialogTitle = '编辑';
			this.dialogVisible = true;
		},
		// 删除 内部分析
		handleDelAnalysis(data, e = {}) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/doRightThing/lookMyself/delete', {
						request: { tableId: data.tableId, rowNum: data.rowNum, ...e }
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.isFullScreen = false;
							this.queryData();
						}
					});
			});
		},
		// 隐藏展示
		handleTitle(i) {
			if (this.isFullScreen) {
				return;
			}
			if (this.activeNames.includes(i)) {
				this.activeNames = this.activeNames.filter((val) => val !== i);
			} else {
				this.activeNames.push(i);
			}
		},
		// 鼠标进入
		mouseEnter(row, inx, key) {
			this.showOperation = [inx, key, row.tableId];
		},
		// 表格 自定义
		getRenderByKey(key, label) {
			return (row, inx) => {
				const showOper = this.showOperation[0] === inx && this.showOperation[1] === key && this.showOperation[2] === row.tableId;

				let showText = row[key];

				if (row[key]?.length > 400) {
					showText = `${showText.substr(0, 400)}...`;
				}
				return (
					<div class="mergeDiv" onMouseenter={() => this.mouseEnter(row, inx, key, label)} onMouseleave={() => (this.showOperation = [])}>
						<el-row style="width:100%">
							<el-col span={22}>
								<div style="margin-left:20px;border: 1px solid #F56C6C;min-height: 100px;padding: 10px;white-space:pre-line">
									{row[key]?.length > 400 ? (
										<el-tooltip popper-class="overText" class="item" effect="dark" content={row[key]} placement="top">
											<span>{showText}</span>
										</el-tooltip>
									) : (
										<span>{showText || ''}</span>
									)}
								</div>
							</el-col>
							{this.permissionControlBtns(this.pageName, 'EditCell') && showOper && (
								<el-col span={2}>
									{
										<div style="cursor:pointer;color:#409EFF;margin-left:10px">
											<i
												class="el-icon-edit"
												style="margin-right: 4px"
												onClick={(e) => this.handleEdit(e, row, inx, key)}
												title="编辑"
											></i>
										</div>
									}
								</el-col>
							)}
						</el-row>
					</div>
				);
			};
		}
	},
	beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
.app-container {
	// padding: 30px 20px !impo rtant;
	.wo_table_item {
		// height: 600px;
		width: 100%;
		// margin: 20px 0;
	}
	.titleRow {
		cursor: pointer;
		// height: 40px;
		// line-height: 40px;
		// border-bottom: 1px solid #dcdcdc;
		padding: 10px;
		display: inline-flex;
		width: 100%;
		align-items: center;
		.titleText {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.4);
		}
		.is-round {
			padding: 7px;
		}
	}

	.views_wrap {
		background: #fff;

		.wo_table_wrap {
			position: relative;
			overflow: hidden;
			border-top: 1.8px solid #dcdcdc;
			.menuTips {
				position: fixed;
			}
		}
		.view_item {
			// box-shadow: 0px 0px 2px #999;
			// margin: 2px;
			margin-bottom: 20px;
			border: 1.8px solid #dcdcdc;
		}
		.fullscreen {
			position: fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			z-index: 1001;
			background: #fff;
		}
	}
	.el-tag {
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.addFunctions {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
}
</style>
<style lang="scss">
.overText {
	max-width: 900px;
}
.queryForm {
	.formStyle {
		margin-bottom: 12px;
	}
	.el-form-item {
		margin-bottom: 10px;
	}
}
.wo_table_wrap {
	.wo_table_item {
		.el-table__row .el-table__cell .cell {
			height: 100%;
			.mergeDiv {
				display: flex;
				justify-content: space-between;
				min-height: 20px;
				height: 100%;
				align-items: center;
			}
		}
		.el-table__row {
			height: 120px;
			&:nth-child(1) td {
				border-bottom: 1px solid #6891ef;
				&:nth-child(1) {
					border-right: 1px solid #6891ef;
					&::before {
						content: ' S \A 优势';
						position: absolute;
						z-index: 16;
						top: calc(50% - 20px);
						left: -32px;
						white-space: pre;
					}
				}
			}
			&:nth-last-child(1) td {
				border-bottom: none;
				&:nth-child(1) {
					border-right: 1px solid #6891ef;
					&::before {
						content: ' W \A 劣势';
						position: absolute;
						z-index: 16;
						top: calc(50% - 20px);
						left: -32px;
						white-space: pre;
					}
					&::after {
						content: 'O 机会';
						position: absolute;
						z-index: 16;
						bottom: -14px;
						left: calc(50% - 20px);
					}
				}
				&:nth-child(2) {
					&::after {
						content: ' T 威胁';
						position: absolute;
						z-index: 16;
						bottom: -14px;
						left: calc(50% - 20px);
					}
				}
			}
		}
		.el-table--border {
			border-top: none;
			border-right: none;
		}
		.tableBase {
			.el-table--border::after,
			.el-table--group::after,
			.el-table::before {
				background-color: transparent;
			}
		}
		.tableBackground {
			border-left: 1px solid #6891ef;
			border-bottom: 1px solid #6891ef;
			// padding: 20px 20px 30px 30px;
			overflow: unset;
			.el-table__body-wrapper {
				.el-table__body {
					padding: 20px 30px 30px 40px;
					table-layout: unset;
					&::before {
						content: '内\A部\A因\A素';
						position: absolute;
						z-index: 16;
						top: calc(50% - 40px);
						left: -30px;
						white-space: pre;
					}
					&::after {
						content: '外部因素';
						position: absolute;
						z-index: 16;
						bottom: -30px;
						left: calc(50% - 40px);
					}
				}

				overflow: unset;
			}
		}
		.tableBackground::before {
			content: '';
			position: absolute;
			z-index: 16;
			top: -10px;
			left: -5px;
			border: 4px solid;
			border-bottom: 10px solid;
			border-color: transparent transparent #6891ef transparent;
			width: 0;
			height: 0;
		}
		.tableBackground::after {
			content: '';
			position: absolute;
			z-index: 16;
			right: -10px;
			bottom: -4px;
			border: 4px solid;
			border-left: 10px solid;
			border-color: transparent transparent transparent #6891ef;
			width: 0;
			height: 0;
		}
	}
}
</style>
