<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="看行业" name="1" v-if="permissionControl('LookIndustry')">
				<industry ref="marketInsight1" v-if="activeName === '1'" pageName="LookIndustry" />
			</el-tab-pane>
			<el-tab-pane label="看竞争" name="2" v-if="permissionControl('LookCompetition')">
				<compete ref="marketInsight2" v-if="activeName === '2'" pageName="LookCompetition" />
			</el-tab-pane>
			<el-tab-pane label="看客户" name="3" v-if="permissionControl('LookCustomers')">
				<customer ref="marketInsight3" v-if="activeName === '3'" pageName="LookCustomers" />
			</el-tab-pane>
			<el-tab-pane label="看自己" name="4" v-if="permissionControl('LookYourself')">
				<myself ref="marketInsight4" v-if="activeName === '4'" pageName="LookYourself" />
			</el-tab-pane>
			<el-tab-pane label="找机会" name="5" v-if="permissionControl('LookOpportunities')">
				<lookForChance ref="marketInsight5" v-if="activeName === '5'" pageName="LookOpportunities" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import industry from './industry.vue';
import compete from './compete.vue';
import customer from './customer.vue';
import myself from './myself.vue';
import lookForChance from './lookForChance.vue';
// import result from './result.vue';
export default {
	name: 'marketInsight',
	components: { industry, compete, customer, myself, lookForChance },

	data() {
		return { activeName: '' };
	},
	watch: {
		activeName() {
			console.log(this.activeName, 'activeName');
		}
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('marketInsightData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'LookIndustry' },
			{ name: '2', key: 'LookCompetition' },
			{ name: '3', key: 'LookCustomers' },
			{ name: '4', key: 'LookYourself' },
			{ name: '5', key: 'LookOpportunities' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'marketInsightData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`marketInsight${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>
