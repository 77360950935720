<template>
	<div id="radar_chart"></div>
</template>

<script>
export default {
	name: 'comonents-radar-chart',
	props: { data: { type: Array }, indicator: { type: Array }, config: { type: Object, default: () => {} }, splitNumber: {} },
	data() {
		return {};
	},
	watch: {
		data: {
			deep: true,
			handler() {
				const { init } = this.$echarts;
				const d = document.getElementById('radar_chart');
				const initD = init(d);

				initD.setOption(this.getOption());
			}
		}
	},
	computed: {
		getOption() {
			return () => ({
				title: {
					text: this.config?.title || ''
				},
				legend: {
					// data: ['Allocated Budget', 'Actual Spending']
				},
				radar: {
					// shape: 'circle',
					indicator: this.indicator,
					splitNumber: this.splitNumber || 5
				},
				series: [
					{
						name: '',
						type: 'radar',
						data: this.data
					}
				]
			});
		}
	},
	mounted() {
		const { init } = this.$echarts;
		const d = document.getElementById('radar_chart');
		const initD = init(d);

		initD.setOption(this.getOption());
	},

	methods: {}
};
</script>

<style lang="scss" scoped>
#radar_chart {
	height: 600px;
	width: 100%;
}
</style>
