<template>
	<div class="app-container">
		<formItem
			class="queryForm"
			style="margin: 10px 0"
			ref="formItem"
			:formItems="formItems"
			@cancel="$router.go(-1)"
			:defaultData="formModel"
			:showFooter="false"
		>
		</formItem>
		<div class="views_wrap">
			<empty v-if="!tableRealData.length" />
			<div v-for="(item, inx) in tableRealData" :key="item.id" :class="`view_item ${isFullScreen && fullScreenInx === inx && 'fullscreen'}`">
				<div @click="() => handleTitle(inx)">
					<el-row class="titleRow">
						<el-col :span="2"><i :class="activeNames.includes(inx) ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i></el-col>
						<el-col :span="8" v-for="ite in titleList" :key="ite.key">
							<div class="titleText">
								<span>{{ ite.label }}: </span>
								<span>{{ ite.formatter ? ite.formatter(item[ite.key]) : item[ite.key] || '' }}</span>
							</div>
						</el-col>
						<el-col :span="8" style="text-align: right; padding-right: 10px" v-show="!isDownLoading">
							<el-button
								size="mini"
								type="primary"
								icon="el-icon-edit"
								@click.stop="() => handleEditAnalysis(item)"
								v-if="permissionControlBtns(pageName, 'Edit')"
							>
								编辑
							</el-button>
							<el-button
								size="mini"
								type="danger"
								icon="el-icon-delete"
								@click.stop="() => handleDelAnalysis(item)"
								v-if="permissionControlBtns(pageName, 'Delete')"
							>
								删除
							</el-button>
						</el-col>
					</el-row>
				</div>
				<el-collapse-transition>
					<div class="table_wrap" v-show="activeNames.includes(inx)">
						<div class="table_item" :style="isFullScreen && `height: 100vh`">
							<baseTable
								:columns="columns"
								:showPagination="false"
								:dataList="item.tableData || []"
								:spanMethod="(e) => spanMethod(e, item.tableId)"
							/>
						</div>
					</div>
				</el-collapse-transition>
			</div>
		</div>
		<!-- 添加客户分析 -->
		<el-dialog :title="dialogTitle" top="30vh" center :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false">
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="dialogItems"
				@submit="this.addAnalysisSubmit"
				@cancel="dialogVisible = false"
				:defaultData="dialogModel"
				style="margin: 0 50px"
			>
			</formItem>
		</el-dialog>
		<!-- 新增编辑节点 以及目标编辑 -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="600px"
			center
			:visible.sync="addNodeVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="dialogNodeItems"
				@submit="this.addNodeSubmit"
				@cancel="addNodeVisible = false"
				:defaultData="dialogFormModel"
			>
			</formItem>
		</el-dialog>
		<!-- 导入按钮的弹窗 -->
		<!-- <Upload :visible.sync="dialogImportVisible" :config="uploadConfig" /> -->
	</div>
</template>

<script>
import formItem from '@/views/components/components-form-item/index.vue';
import baseTable from '@/views/components/components-base-table';
import empty from '@/views/components/components-empty/index.vue';
// import Upload from '@/components/Upload/index.vue';
// import { debounce } from 'loadsh';
import { Loading } from 'element-ui';
import moment from 'moment';
// import { printDiv } from '@/utils/util';
export default {
	name: 'marketInsightindustry',
	components: { formItem, baseTable, empty },
	props: {
		pageName: {}
	},
	data() {
		return {
			visible: true,
			dialogVisible: false,
			dialogModel: {},
			dialogItems: [
				{
					label: '产品线',
					key: 'productId',
					type: 'select',
					class: 'pruductClass',
					defaultFirstOption: true,
					options: () => {
						const { setData } = this.$store.state;

						return setData.options.productId || [];
					},
					optEdit: true,
					footerRender: true,
					optProps: { add: '/v1/common/productConfig/save', del: '/v1/common/productConfig/delete', key: 'name' },
					rules: [{ required: true, message: '请选择', trigger: 'change' }]
				},
				{
					label: '客户',
					key: 'customers',
					rules: { required: true, message: '请输入', trigger: 'blur' },
					render: (formData, it, form) => (
						<div>
							{this.dynamicCustTags.map((key) => (
								<el-tag key={key} closable disable-transitions={false} onClose={() => this.delCustTags(key, form, formData, it)}>
									{key}
								</el-tag>
							))}
							{this.inputVisible === it.key ? (
								<el-input
									class="input-new-tag"
									v-model={this.addInputVal}
									ref={`${it.key}Ref`}
									size="small"
									onBlur={() => this.addCustTags(formData, it, form)}
								/>
							) : (
								<el-button
									class="button-new-tag"
									size="small"
									onClick={() => this.showAddInput(it)}
									icon="el-icon-plus"
									title="新增"
								></el-button>
							)}
						</div>
					)
				},
				{
					label: '分析方向',
					key: 'analysisDirections',
					rules: { required: true, message: '请输入', trigger: 'blur' },
					render: (formData, it, form) => (
						<div>
							{this.dynamicTags.map((key) => (
								<el-tag key={key} closable disable-transitions={false} onClose={() => this.delTags(key, form, formData, it)}>
									{key}
								</el-tag>
							))}
							{this.inputVisible === it.key ? (
								<el-input
									class="input-new-tag"
									v-model={this.addInputVal}
									ref={`${it.key}Ref`}
									size="small"
									onBlur={() => this.addTags(formData, it, form)}
								/>
							) : (
								<el-button
									class="button-new-tag"
									size="small"
									onClick={() => this.showAddInput(it)}
									icon="el-icon-plus"
									title="新增"
								></el-button>
							)}
						</div>
					)
				}
			],
			formItems: [
				{
					label: '时间',
					key: 'annual',
					type: 'year',
					span: 6,
					labelWidth: '60px'
				},
				{
					label: '产品线',
					key: 'productId',
					type: 'select',
					async: () =>
						this.$axios
							.get('/v1/common/productConfig/getList', { request: {} })
							.then((res) => res.map(({ id, name }) => ({ dictId: id, dictName: name }))),
					span: 6
				},
				{
					span: 3,
					labelWidth: '0px',
					style: 'min-width: 100px',
					render: () => (
						<el-button size="small" type="primary" style="margin-top: 5px" icon="el-icon-search" onclick={this.queryData}>
							查询
						</el-button>
					)
				},
				this.permissionControlBtns(this.pageName, 'Add') && {
					span: 9,
					labelWidth: '0px',
					style: 'text-align: right;min-width: 310px',
					render: () => (
						<div style="min-width: 310px">
							<el-button
								size="small"
								type="primary"
								style="margin-top: 5px"
								icon="el-icon-plus"
								onclick={() => {
									this.dialogModel = {};
									this.dynamicTags = [];
									this.dynamicCustTags = [];
									this.dialogTitle = '添加客户分析';
									this.dialogVisible = true;
								}}
							>
								添加客户分析
							</el-button>
						</div>
					)
				}
			].filter((v) => v),
			formModel: { annual: new Date().getFullYear().toString() },
			columns: [
				{
					label: '客户',
					key: 'customer',
					render: this.getRenderByKey('customer'),
					showOverflow: false,
					align: 'left'
				},
				{
					label: '分析方向',
					key: 'analysisDirection',
					render: this.getRenderByKey('analysisDirection'),
					showOverflow: false,
					align: 'left'
				},
				{
					label: '客户需求变化情况',
					key: 'demand',
					render: this.getRenderByKey('demand'),
					showOverflow: false,
					align: 'left'
				},
				{
					label: '应当策略变化情况',
					key: 'strategy',
					render: this.getRenderByKey('strategy'),
					showOverflow: false,
					align: 'left'
				}
			],
			dataList: [],
			titleList: [
				{
					label: '时间',
					key: 'createTime',
					formatter: (val) => moment(val).format('YYYY-MM-DD')
				},
				{ label: '产品线', key: 'productName' }
				// { label: '所属行业', key: 'industry' }
			],
			showMenuTips: false,
			menuStyle: {},
			addNodeVisible: false,
			addTagVisible: false,
			dialogFormModel: {},
			dialogTitle: '新增',
			dialogType: '',
			treeChartList: [],
			// debounceResize: debounce(this.resize, 300),
			activeNames: [0],
			isDownLoading: false,
			dialogImportVisible: false,
			// 导入配置
			// uploadConfig: {
			// 	dictId: 'importTargetInfo',
			// 	groupId: 'importTemplate',
			// 	importUrl: '/v1/performance/target/importTargetInfo',
			// 	successMsg: '年度目标差距分析导入成功',
			// 	callBack: () => {
			// 		this.queryData();
			// 		this.dialogImportVisible = false;
			// 	}
			// },
			inputVisible: false,
			inputTagValue: '',
			selectColor: '',
			isFullScreen: false,
			fullScreenInx: '',
			dynamicTags: [],
			addInputVal: '',
			tableRealData: [],
			editNodeData: {},
			showOperation: [],
			dynamicCustTags: []
		};
	},
	watch: {},
	computed: {
		dialogNodeItems() {
			const { targetLabel, targetKey } = this.editNodeData || {};

			const arr = [
				{
					label: targetLabel || '',
					key: targetKey || 'addOrEdit',
					type: 'input',
					span: 24,
					rules: { required: true, message: '请输入', trigger: 'blur' }
				}
			];

			return arr;
		},
		merges() {
			const mers = {};

			this.tableRealData.forEach(({ children, tableId }) => {
				if (children && children.length) {
					mers[tableId] = this.getMergesByTableData(children);
				}
			});
			return mers;
		}
	},
	async mounted() {
		// 查询数据
		this.queryData();
	},

	methods: {
		// 查询数据视图
		queryData() {
			const loading = Loading.service({ target: '.views_wrap' });

			this.$axios
				.post('/v1/doRightThing/lookCustomer/getList', { request: { ...this.formModel } })
				.then((res) => {
					loading.close();
					if (res.code) {
						return;
					}
					const arr = [];

					this.$delete(res, '_responseStatusCode');
					Object.keys(res).forEach((key) => {
						arr.push({ children: res[key], ...(res[key]?.[0] || {}) });
					});
					this.tableRealData = arr.map((val) => ({ ...val, tableData: this.unfoldListCol(val.children) }));
				})
				.catch(() => {
					loading.close();
				});
		},
		// 添加一行
		addRow(row) {
			this.dataList.push({ ...row });
		},
		// 删除一行
		delRow(row, inx) {
			if (this.dataList?.length < 2) {
				return;
			}
			this.dataList.splice(inx, 1);
		},
		// 添加客户分析提交
		addAnalysisSubmit(form) {
			form.validate((valid) => {
				if (valid) {
					const loading = Loading.service({ target: '.formItem' });

					this.$axios
						.post('/v1/doRightThing/lookCustomer/saveAnalysis', {
							request: { ...this.dialogModel }
						})
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success('保存成功');
							this.dialogModel = {};
							this.queryData();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		},
		// 编辑行业 分析
		addNodeSubmit(form) {
			form.validate((valid) => {
				if (valid) {
					const params = {
						...this.editNodeData,
						...this.dialogFormModel,
						id: this.dialogType === 'editNode' ? this.editNodeData.id : ''
					};
					const loading = Loading.service({ target: '.formItem' });

					this.$axios
						.post('/v1/doRightThing/lookCustomer/saveCustomerInfo', {
							request: params
						})
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.addNodeVisible = false;
							this.$message.success('保存成功');
							this.editNodeData = {};
							this.queryData();
						})
						.catch(() => {
							this.addNodeVisible = false;
						});
				}
			});
		},
		// 嵌套数据铺平
		unfoldListCol(cols) {
			if (cols?.length === 0 || !cols) {
				return [];
			}
			let arr = [];

			cols.forEach((item) => {
				const p = item.child?.length ? [{ ...item }, ...this.unfoldListCol(item.child)] : item;

				arr = arr.concat(p);
			});
			return arr;
		},
		// 新增分析方向
		addTags(formData, it, form) {
			if (this.addInputVal) {
				this.dynamicTags.push(this.addInputVal);
				formData[it.key] = this.dynamicTags;
				form.clearValidate('analysisDirections');
			}
			this.inputVisible = false;
			this.addInputVal = '';
		},
		// 新增客户
		addCustTags(formData, it, form) {
			if (this.addInputVal) {
				this.dynamicCustTags.push(this.addInputVal);
				formData[it.key] = this.dynamicCustTags;
				form.clearValidate('customers');
			}
			this.inputVisible = false;
			this.addInputVal = '';
		},
		// 删除分析方向
		delTags(tag, form, formData, it) {
			this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
			formData[it.key] = this.dynamicTags;
			if (!this.dynamicTags?.length) {
				form.validateField('analysisDirections');
			}
		},
		// 删除客户
		delCustTags(tag, form, formData, it) {
			this.dynamicCustTags.splice(this.dynamicCustTags.indexOf(tag), 1);
			formData[it.key] = this.dynamicCustTags;
			if (!this.dynamicCustTags?.length) {
				form.validateField('customers');
			}
		},
		// 删除节点
		handleDel() {
			this.showMenuTips = false;
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				const { tableId, targetKey } = this.editNodeData;

				this.$axios
					.post('/v1/doRightThing/lookCustomer/deleteRow', {
						request: { ...this.editNodeData, tableId, [targetKey]: this.editNodeData[targetKey], id: '' }
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');

							this.queryData();
						}
					});
			});
		},
		// 新增节点
		handleAdd() {
			this.dialogType = 'addNode';
			this.dialogTitle = `新增${this.editNodeData.targetLabel}`;
			this.showMenuTips = false;
			this.addNodeVisible = true;
			this.dialogFormModel = {};
		},
		// 编辑节点
		handleEdit() {
			this.dialogType = 'editNode';
			this.showMenuTips = false;
			this.addNodeVisible = true;
			this.dialogTitle = '编辑';
			this.dialogFormModel = { ...this.editNodeData };
		},
		// 编辑 分析
		handleEditAnalysis({ id, productId, productName, industry, children, tableId }) {
			this.dialogType = 'editAnalysis';
			const params = {
				id,
				tableId,
				productId,
				productName,
				industry,
				analysisDirections: children?.map(({ analysisDirection }) => analysisDirection),
				customers: children?.map(({ customer }) => customer)
			};

			if (params.analysisDirections) {
				this.dynamicTags = Array.from(new Set(params.analysisDirections));
				params.analysisDirections = this.dynamicTags;
			}
			if (params.customers) {
				this.dynamicCustTags = Array.from(new Set(params.customers));
				params.customers = this.dynamicCustTags;
			}
			this.dialogModel = params;
			this.dialogTitle = '编辑';
			this.dialogVisible = true;
		},
		// 删除 行业分析
		handleDelAnalysis(data) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/doRightThing/lookCustomer/deleteRow', {
						request: { tableId: data.tableId }
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.isFullScreen = false;
							// this.queryData();
							this.tableRealData = this.tableRealData.filter(({ tableId }) => tableId !== data.tableId);
						}
					});
			});
		},
		// 隐藏展示
		handleTitle(i) {
			if (this.isFullScreen) {
				return;
			}
			if (this.activeNames.includes(i)) {
				this.activeNames = this.activeNames.filter((val) => val !== i);
			} else {
				this.activeNames.push(i);
			}
		},
		// 全屏
		handleFullScreen(i) {
			this.isFullScreen = !this.isFullScreen;
			this.fullScreenInx = i;
			this.activeNames = [i];
			// this.$nextTick(() => {
			// 	// this.resize();
			// });
		},
		showAddInput(it) {
			this.inputVisible = it.key;
			this.$nextTick(() => {
				this.$refs[`${it.key}Ref`].$refs.input.focus();
			});
		},
		mouseEnter(row, inx, key) {
			const colInx = this.columns.map(({ key }) => key).indexOf(key);
			const { label, key: targetKey } = this.columns[colInx];

			this.editNodeData = { ...row, targetLabel: label, targetKey };
			this.showOperation = [inx, key, row.tableId];
		},
		// 计算合并数据
		getMergesByTableData(data) {
			const colKeys = this.columns.map(({ key }) => key);

			const merges = [];

			colKeys.forEach((key, inx) => {
				if (!merges[inx]) {
					merges[inx] = [];

					const getLen = () => {
						const str = data[merges[inx].length]?.[key];

						const lastStr = data[merges[inx].length]?.[colKeys[inx - 1]];

						// 重复的数量
						let num = data.filter((it) => it[key] && str && it[key] === str)?.length;

						// 除了首列 合并必须要求当前列上一列的数据相同
						if (inx !== 0 && num > 1) {
							num = data.filter((it) => it[key] && str && it[colKeys[inx - 1]] && it[key] === str && lastStr === it[colKeys[inx - 1]])
								?.length;
						}
						if (num > 1) {
							const mergesLen = merges[inx].length;

							for (let i = 0; i < num; i++) {
								merges[inx].push({ rowspan: 0, colspan: 0 });
							}
							merges[inx][mergesLen] = { rowspan: num, colspan: 1 };
						} else {
							merges[inx].push({ rowspan: 1, colspan: 1 });
						}
						if (merges[inx].length < data.length) {
							getLen();
						}
					};

					getLen();
				}
			});
			return merges;
		},
		// 合并单元格
		// eslint-disable-next-line no-unused-vars
		spanMethod({ row, column, rowIndex, columnIndex }, tableId) {
			return this.merges?.[tableId]?.[columnIndex]?.[rowIndex];
		},
		// 表格 自定义
		getRenderByKey(key) {
			return (row, inx) => {
				const showOper = this.showOperation[0] === inx && this.showOperation[1] === key && this.showOperation[2] === row.tableId;

				const showNotAddDel = !['customer', 'analysisDirection'].includes(this.editNodeData.targetKey);

				return (
					<div class="mergeDiv" onMouseenter={() => this.mouseEnter(row, inx, key)} onMouseleave={() => (this.showOperation = [])}>
						<el-row style="width:100%">
							<el-col span={17}>{row[key] || ''}</el-col>
							{showOper && (
								<el-col span={7}>
									{
										<div style="cursor:pointer;color:#409EFF">
											{this.permissionControlBtns(this.pageName, 'EditCell') && showNotAddDel && (
												<i class="el-icon-edit" style="margin-right: 4px" onClick={this.handleEdit} title="编辑"></i>
											)}
											{this.permissionControlBtns(this.pageName, 'AddCell') && showNotAddDel && (
												<i class="el-icon-plus" style="margin-right: 4px" onClick={this.handleAdd} title="新增"></i>
											)}
											{this.permissionControlBtns(this.pageName, 'DelCell') && showNotAddDel && (
												<i
													class="el-icon-delete"
													style="margin-right: 4px;color:#f56c6c"
													onClick={this.handleDel}
													title="删除"
												></i>
											)}
										</div>
									}
								</el-col>
							)}
						</el-row>
					</div>
				);
			};
		}
	},
	beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
.app-container {
	// padding: 30px 20px !impo rtant;
	.table_item {
		// height: 600px;
		width: 100%;
		// margin: 20px 0;
	}
	.titleRow {
		cursor: pointer;
		// height: 40px;
		// line-height: 40px;
		// border-bottom: 1px solid #dcdcdc;
		padding: 10px;
		display: inline-flex;
		width: 100%;
		align-items: center;
		.titleText {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.4);
		}
		.is-round {
			padding: 7px;
		}
	}

	.views_wrap {
		background: #fff;

		.table_wrap {
			position: relative;
			overflow: hidden;
			border-top: 1.8px solid #dcdcdc;
			.menuTips {
				position: fixed;
			}
		}
		.view_item {
			// box-shadow: 0px 0px 2px #999;
			// margin: 2px;
			margin-bottom: 20px;
			border: 1.8px solid #dcdcdc;
		}
		.fullscreen {
			position: fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			z-index: 1001;
			background: #fff;
		}
	}
	.el-tag {
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}
	.tag-title {
		height: 30px;
		line-height: 30px;
	}
	.color-item {
		height: 30px;
		ul {
			display: inline-flex;
			width: 100%;
			height: 100%;
			li {
				margin-right: 20px;
				margin-bottom: 10px;
				width: 20px;
				border-radius: 50%;
				cursor: pointer;
				opacity: 0.6;
			}
		}
	}
	.tag-item {
		cursor: pointer;
		border: none;
	}
	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
		margin-right: 10px;
		margin-bottom: 10px;
	}
}
</style>
<style lang="scss">
.queryForm {
	.formStyle {
		margin-bottom: 12px;
	}
	.el-form-item {
		margin-bottom: 10px;
	}
}
.table_wrap {
	.table_item {
		.el-table__row .el-table__cell .cell {
			height: 100%;
			.mergeDiv {
				display: flex;
				justify-content: space-between;
				min-height: 20px;
				height: 100%;
				align-items: center;
			}
		}
	}
}
</style>
